import { GraphQLClient } from 'graphql-request'
import store from '@/store'
import { log, logException } from './logging.js'

export class Api {

  constructor(opts = {}) {

    this.store = store
    this.router = opts?.router

    this.client = new GraphQLClient(process.env.VUE_APP_GRAPHQL_URL)
  }

  async checkVersion(app, version, firebaseToken) {

    const query = `
      query checkVersion($app: String, $version: String) {
          checkVersion(app: $app, version: $version)
      }
    `

    let { checkVersion, errors } = await this._query(query, { app, version, firebaseToken })

    return checkVersion
  }

  async addPushToken(app, firebaseToken) {

    const query = `
      mutation addPushToken($app: String, $firebaseToken: String) {
          addPushToken(app: $app, firebaseToken: $firebaseToken)
      }
    `

    let { acceptBid } = await this._query(query, { app, firebaseToken })

    return acceptBid
  }

  async acceptBid(bidId) {

    const query = `
      mutation AcceptBid($bidId: Int) {
          acceptBid(bidId: $bidId) {
            ${bidSchema}
          }
      }
    `

    let { acceptBid } = await this._query(query, { bidId })

    return acceptBid
  }

  async cancelCampaign(campaignId) {
    const query = `
      mutation CancelCampaign($campaignId: Int){
        cancelCampaign(campaignId:$campaignId)
      }
    `
    let { cancelCampaign } = await this._query(query, { campaignId })

    return cancelCampaign
  }

  async cancelTicket(ticketId) {
    const query = `
      mutation cancelTicket($ticketId: Int){
        cancelTicket(ticketId:$ticketId)
      }
    `
    let { cancelTicket } = await this._query(query, { ticketId })

    return cancelTicket
  }

  async checkQrCode(venueId, campaignId, qrCode, checkUserRole) {
    const query = `
      query CheckQrCode($checkQrCode: CheckQrCodeInput) {
        checkQrCode(checkQrCode: $checkQrCode) {
          user {
            id
            name
            firstName
          }
          scanCount
          scanIndex
        }
      }
    `

    let { checkQrCode } = await this._query(query, { checkQrCode: { venueId, campaignId, qrCode, checkUserRole } })

    return checkQrCode
  }

  async canCompleteTicketPurchase(purchase) {

    const query = `
      mutation canCompleteTicketPurchase($purchase: TicketPurchaseInput) {
        canCompleteTicketPurchase(purchase: $purchase)
      }
    `
    let { canCompleteTicketPurchase, errors } = await this._query(query, { purchase })

    return canCompleteTicketPurchase
  }

  async completeTicketPurchase(purchase) {

    const query = `
      mutation CompleteTicketPurchase($purchase: TicketPurchaseInput) {
        completeTicketPurchase(purchase: $purchase){
          id
        }
      }
    `
    let { completeTicketPurchase } = await this._query(query, { purchase })

    return completeTicketPurchase
  }

  async createArtist(artist) {
    const query = `
      mutation CreateArtist($artist: ArtistInput) {
        createArtist(artist: $artist){
          ${artistSchema}
        }
      }
    `

    let { createArtist } = await this._query(query, { artist })

    return createArtist
  }

  async createBid(campaignId, venueId, eventDate) {

    const query = `
      mutation CreateBid($bid: BidInput) {
        createBid(bid: $bid) {
          ${bidSchema}
        }
      }
    `

    let { createBid } = await this._query(query, { bid: { campaignId, venueId, eventDate } })

    return createBid
  }

  async createCampaign(campaign) {
    const query = `
      mutation CreateCampaign($campaign: CampaignInput) {
        createCampaign(campaign: $campaign) {
          ${campaignSchema}
        }
      }
    `

    let { createCampaign } = await this._query(query, { campaign })

    return createCampaign
  }

  async createCampaignArtistRating(campaignId, score, comment) {

    const query = `
      mutation CreateCampaignArtistRating($campaignId: Int, $score: Int, $comment: String) {
        createCampaignArtistRating(campaignId: $campaignId, score: $score, comment: $comment)
      }
    `

    let { createCampaignArtistRating } = await this._query(query, { campaignId, score, comment })

    return createCampaignArtistRating
  }

  async createCampaignVenueRating(campaignId, score, comment) {

    const query = `
      mutation CreateCampaignVenueRating($campaignId: Int, $score: Int, $comment: String) {
        createCampaignVenueRating(campaignId: $campaignId, score: $score, comment: $comment)
      }
    `

    let { createCampaignVenueRating } = await this._query(query, { campaignId, score, comment })

    return createCampaignVenueRating
  }

  async createInvitation(targetType, targetId, role) {

    const query = `
      mutation CreateInvitation($targetId: Int, $targetType: String, $role: String){
        createInvitation(targetId: $targetId, targetType: $targetType, role: $role) {
          id
          userId
          targetId
          targetType
          role
          code
          status
        }
      }
    `

    let { createInvitation } = await this._query(query, { targetId, targetType, role })

    return createInvitation
  }

  async createInvitationForArtist(artistId) {

    const query = `
      mutation createInvitationForArtist($artistId: Int) {
        createInvitationForArtist(artistId: $artistId) {
          url
          token {
            id
          }
        }
      }
    `

    let { createInvitationForArtist, errors } = await this._query(query, { artistId })

    return createInvitationForArtist
  }

  async createInvitationForVenue(venueId) {

    const query = `
      mutation createInvitationForVenue($venueId: Int) {
        createInvitationForVenue(venueId: $venueId) {
          url
          token {
            id
          }
        }
      }
    `
    let { createInvitationForVenue, errors } = await this._query(query, { venueId })

    return createInvitationForVenue
  }

  // deprecated, v1.6.3 and under
  async createStripePaymentIntent(amount, campaignId) {

    const query = `
      mutation CreateStripePaymentIntent($amount: Float) {
        createStripePaymentIntent(amount: $amount)
      }
    `

    let { createStripePaymentIntent } = await this._query(query, { amount, campaignId })

    return createStripePaymentIntent
  }

  async createStripePaymentIntentWithCampaign(amount, campaignId) {

    const query = `
      mutation createStripePaymentIntentWithCampaign($amount: Float, $campaignId: Int) {
        createStripePaymentIntentWithCampaign(amount: $amount, campaignId: $campaignId)
      }
    `

    let { createStripePaymentIntentWithCampaign } = await this._query(query, { amount, campaignId })

    return createStripePaymentIntentWithCampaign
  }

  async createTicketTransfer(ticketId) {

    const query = `
      mutation createTicketTransfer($ticketId: Int) {
        createTicketTransfer(ticketId: $ticketId) {
          url
          token {
            id
          }
        }
      }
    `
    let { createTicketTransfer, errors } = await this._query(query, { ticketId })

    return createTicketTransfer
  }

  async createUser(firstName, lastName, email, googleId, facebookId, appleId, firebaseToken) {

    const query = `
      mutation CreateUser($user: UserInput){
        createUser(user: $user){
          id
          name
          email
          googleId
          facebookId
          appleId
          token
        }
      }
    `
    let { createUser } = await this._query(query, {
      user: { firstName, lastName, email, googleId, facebookId, appleId, firebaseToken }
    })

    return createUser
  }

  async createVenue(venue) {
    const query = `
      mutation CreateVenue($venue: VenueInput) {
        createVenue(venue: $venue){
          ${venueSchema}
        }
      }
    `

    let { createVenue } = await this._query(query, { venue })

    return createVenue
  }

  async deleteAccount() {

    const query = `
      mutation DeleteAccount {
        deleteAccount
      }
    `
    await this._query(query)
  }

  async deleteArtist(artistId) {

    const query = `
      mutation DeleteArtist($artistId: Int) {
        deleteArtist(artistId: $artistId)
      }
    `

    let { deleteArtist } = await this._query(query, { artistId })

    return deleteArtist
  }

  async deleteBid(bidId) {

    const query = `
      mutation DeleteBid($bidId: Int) {
        deleteBid(bidId: $bidId)
      }
    `
    await this._query(query, { bidId })
  }

  async deleteMember(userProfileId) {

    const query = `
      mutation deleteUserProfile($userProfileId: Int){
        deleteUserProfile(userProfileId: $userProfileId)
      }
    `
    let { deleteUserProfile, errors } = await this._query(query, { userProfileId })

    return deleteUserProfile
  }

  async deleteVenue(venueId) {

    const query = `
      mutation DeleteVenue($venueId: Int) {
        deleteVenue(venueId: $venueId)
      }
    `

    let { deleteVenue } = await this._query(query, { venueId })

    return deleteVenue
  }

  async getBidForUser(bidId) {

    const query = `
      query GetBidForUser($bidId: Int) {
        getBidForUser(bidId: $bidId) {
          id
          campaignId
          eventDate
          accepted
          venueId
          venue {
            ${venueSchema}
          }
        }
      }
        `

    let { getBidForUser } = await this._query(query, { bidId })

    return getBidForUser
  }

  async getArtist(artistId) {

    const query = `
      query GetArtist($artistId: Int) {
        getArtist(artistId: $artistId) {
          ${artistSchema}
        }
      }
    `

    let { getArtist } = await this._query(query, { artistId })

    return getArtist
  }

  async getCampaign(campaignId) {

    const query = `
      query GetCampaign($campaignId: Int) {
        getCampaign(campaignId: $campaignId) {
          ${campaignSchema}
        }
      }
    `

    let { getCampaign } = await this._query(query, { campaignId })

    return getCampaign
  }

  async getCampaignForUser(campaignId) {
    const query = `
      query GetCampaignForUser($campaignId: Int) {
        getCampaignForUser(campaignId: $campaignId) {
          ${campaignSchema}
        }
      }
    `
    let { getCampaignForUser } = await this._query(query, { campaignId })

    return getCampaignForUser
  }

  async getDetailInvitationLink(token) {
    const query = `
      query GetDetailInvitationLink($token: String){
        getDetailInvitationLink(token: $token){
          name
          senderId
          targetUserId
          targetType
          title
        }
      }
    `
    let { getDetailInvitationLink } = await this._query(query, { token })

    return getDetailInvitationLink
  }

  async getTicketForUser(ticketId) {

    const query = `
      query GetTicketForUser($ticketId: Int) {
        getTicketForUser(ticketId: $ticketId) {
          id,
          userId,
          campaignId,
          qrCode,
          qrCodeScannedCount
          createdAt,
          campaign {
            ${campaignSchema}
          }
        }
      }
        `

    let { getTicketForUser } = await this._query(query, { ticketId })

    return getTicketForUser
  }

  async getUploadUrl(bucket, key, contentType) {

    const query = `
      query GetUploadUrl($bucket: String, $key: String, $contentType: String) {
        getUploadUrl(bucket: $bucket, key: $key, contentType: $contentType)
      }
    `

    let { getUploadUrl } = await this._query(query, { bucket, key, contentType })

    return getUploadUrl

  }

  async getVenue(venueId) {

    const query = `
      query GetVenue($venueId: Int) {
        getVenue(venueId: $venueId) {
          ${venueSchema}
        }
      }
    `

    let { getVenue } = await this._query(query, { venueId })

    return getVenue
  }

  async listArtistMembers(bandId) {

    const query = `
      query ListBandMembers($bandId: Int){
        listBandMembers(bandId: $bandId) {
          userProfileId
          id,
          firstName,
          lastName,
          email,
          role
        }
      }
    `
    let { listBandMembers } = await this._query(query, { bandId })

    return listBandMembers
  }

  async listBidsForArtist(artistId) {
    const query = `
      query ListBidsForArtist($artistId: Int) {
        listBidsForArtist(artistId: $artistId) {
          id
          eventDate
          accepted
          campaignId
          campaign {
            ${campaignSchema}
          }
          venueId
          venue {
            ${venueSchema}
          }
        }
      }
    `

    let { listBidsForArtist } = await this._query(query, { artistId })

    return listBidsForArtist
  }

  async listBidsForCampaign(campaignId) {
    const query = `
      query ListBidsForCampaign($campaignId: Int) {
        listBidsForCampaign(campaignId: $campaignId) {
          id
          eventDate
          accepted
          campaignId
          campaign {
            ${campaignSchema}
          }
          venueId
          venue {
            ${venueSchema}
          }
        }
      }
    `

    let { listBidsForCampaign } = await this._query(query, { campaignId })

    return listBidsForCampaign
  }

  async listBidsForVenue(venueId) {
    const query = `
      query ListBidsForVenue($venueId: Int) {
        listBidsForVenue(venueId: $venueId) {
          id
          eventDate
          accepted
          campaignId
          campaign {
            ${campaignSchema}
          }
          venueId
          venue {
            ${venueSchema}
          }
        }
      }
    `

    let { listBidsForVenue } = await this._query(query, { venueId })

    return listBidsForVenue
  }

  async listCampaigns() {

    const query = `
      query listCampaigns {
        listCampaigns {
          ${campaignSchema}
        }
      }
    `

    let { listCampaigns } = await this._query(query)

    return listCampaigns
  }

  async listCampaignsForUser() {

    const query = `
      query ListCampaignsForUser {
        listCampaignsForUser {
          ${campaignSchema}
        }
      }
    `
    let { listCampaignsForUser } = await this._query(query)

    return listCampaignsForUser
  }

  async listGenres() {

    const query = `
      query ListGenres {
        listGenres {
          id
          type
        }
      }
    `

    let { listGenres } = await this._query(query)

    return listGenres
  }

  async listProfilesForUser() {

    const query = `
      query ListProfilesForUser {
        listProfilesForUser {
            ${profileSchema}
        }
      }
    `

    let { listProfilesForUser } = await this._query(query)

    return listProfilesForUser
  }

  async listTicketsForUser() {
    const query = `
      query ListTicketsForUser {
        listTicketsForUser {
          ${ticketSchema}
        }
      }
    `
    let { listTicketsForUser } = await this._query(query)

    return listTicketsForUser
  }

  async listVenueMembers(venueId) {

    const query = `
      query ListVenueMembers($venueId: Int){
        listVenueMembers(venueId: $venueId) {
          userProfileId
          id,
          firstName,
          lastName,
          email,
          role
        }
      }
    `
    let { listVenueMembers } = await this._query(query, { venueId })

    return listVenueMembers
  }

  async login(credentials) {

    const query = `
      query LoginStandard ($credentials: CredentialsInput)  {
        loginStandard (credentials: $credentials) {
          id,
          name,
          email,
          placeId,
          city,
          state,
          address,
          token
        }
      }
    `
    let { loginStandard } = await this._query(query, { credentials })

    return loginStandard
  }

  async readInvitation(tokenId) {

    const query = `
      query readInvitation($tokenId: String) {
        readInvitation(tokenId: $tokenId) {
          id
          payload
          isActive
        }
      }
    `
    let { readInvitation, errors } = await this._query(query, { tokenId })

    return readInvitation
  }

  async redeemInvitation(tokenId) {

    const query = `
      mutation redeemInvitation($tokenId: String) {
        redeemInvitation(tokenId: $tokenId){
          id
          name
          type
          role
          isVerified
        }
      }
    `
    let { redeemInvitation, errors } = await this._query(query, { tokenId })

    return redeemInvitation
  }

  async readTicketTransfer(tokenId) {

    const query = `
      query readTicketTransfer($tokenId: String) {
        readTicketTransfer(tokenId: $tokenId) {
          ${ticketSchema}
        }
      }
    `
    let { readTicketTransfer, errors } = await this._query(query, { tokenId })

    return readTicketTransfer
  }

  async redeemTicketTransfer(tokenId) {

    const query = `
      mutation redeemTicketTransfer($tokenId: String) {
        redeemTicketTransfer(tokenId: $tokenId) {
          ${ticketSchema}
        }
      }
    `
    let { redeemTicketTransfer, errors } = await this._query(query, { tokenId })

    return redeemTicketTransfer
  }

  async syncFanAppData() {

    const query = `
      query syncFanAppData {
        syncFanAppData {
          profiles {
            ${profileSchema}
          }
        }
      }
    `
    let { syncFanAppData, errors } = await this._query(query)

    return syncFanAppData
  }

  async updateArtist(artistId, updates) {

    const query = `
      mutation UpdateArtist($artistId: Int, $updates: ArtistInput) {
        updateArtist(artistId: $artistId, updates: $updates){
          ${artistSchema}
        }
      }
    `
    let { updateArtist } = await this._query(query, { artistId, updates })

    return updateArtist
  }

  async updateCampaign(campaignId, updates) {
    const query = `
      mutation UpdateCampaign($campaignId: Int, $updates: CampaignInput) {
        updateCampaign(campaignId: $campaignId, updates: $updates) {
          ${campaignSchema}
        }
      }
    `

    let { updateCampaign } = await this._query(query, { campaignId, updates })

    return updateCampaign
  }

  async updateProfile({ name, description }) {
    const query = `
      mutation UpdateProfile($name: String, $description: String){
        updateProfile(name: $name, description: $description)
      }
    `
    let { updateProfile } = await this._query(query, { name, description })

    return updateProfile
  }

  async updateAccount(updates) {

    const query = `
      mutation updateAccount($updates: AccountInput) {
        updateAccount(updates: $updates) {
          id
          name
          email
          city
        }
      }
    `

    let { updateUserProfile } = await this._query(query, { updates })

    return updateUserProfile
  }

  async updateVenue(venueId, updates) {

    const query = `
      mutation UpdateVenue($venueId: Int, $updates: VenueInput) {
        updateVenue(venueId: $venueId, updates: $updates){
          ${venueSchema}
        }
      }
    `
    let { updateVenue } = await this._query(query, { venueId, updates })

    return updateVenue
  }

  /// deprecated

  async addUser(firstName, lastName, email, googleId, facebookId, appleId, firebaseToken) {

    const query = `
      mutation AddUser($user: UserInput){
        addUser(user: $user){
          id
          name
          email
          googleId
          facebookId
          appleId
          token
        }
      }
    `
    let { addUser } = await this._query(query, {
      user: {
        firstName, lastName, email, googleId, facebookId, appleId, firebaseToken
      }
    })

    return addUser
  }

  addFanArtists(artists) {
    const query = `
    mutation AddFanArtists($artists: [FanArtistInput]){
      addFanArtists(artists: $artists)
    }
    `
    return this._query(query, { artists })
  }

  addFanGenre(genres) {
    const query = `
      mutation AddFanGenre($genres: [FanGenreInput]){
        addFanGenre(genres: $genres)
      }
    `
    return this._query(query, { genres })
  }

  // async bidCampaign(campaignId, venueId, eventDate) {
  //
  //   const query = `
  //         mutation BidCampaign($bid: BidInput) {
  //             bidCampaign(bid: $bid)
  //         }
  //   `
  //
  //   let { bidCampaign } = await this._query(query, { bid: { campaignId, venueId, eventDate } })
  //
  //   return bidCampaign
  // }

  getGenreList(type, limit) {
    console.log('api getGenreList', type, limit)
    const query = `
      query GetGenreList($type: String, $limit: Int){
        getGenreList(type: $type, limit: $limit){
          id
          type
        }
      }
    `
    return this._query(query, { type, limit })
  }

  async _query(query, vars) {

    try {

      let token = this.store.state.user?.token
      let appVersion = this.store.state.appVersion
      let platform = `${typeof device !== 'object' || device?.platform?.toLowerCase()}`

      // console.log('STATE', this.store.state)

      this.client.setHeader('authorization', `${token}`)
      this.client.setHeader('app-version', appVersion)
      this.client.setHeader('app-platform', platform)
      this.client.setHeader('app-name', 'gigstan')

      return await this.client.request(query, vars)

    } catch (error) {

      logException('API ERRORS', error)

      let err = error?.response?.errors[0] || { code: 500, message: 'SERVER ERROR' }

      log('ERROR CODE', err.code)

      if (err.code === 401) {

        if (this.router.currentRoute) {

          console.log('[UNAUTHORIZED]', this.router.currentRoute)

          sessionStorage.setItem('redirectUrl', this.router.currentRoute.fullPath)
        }

        return await this.router.push({ name: 'Logout', params: { reason: 'unauthorized' } })
      }

      throw err
    }
  }
}

const artistSchema = `
  id
  name
  description
  postalCode
  geolocationData
  lineup
  primaryContactName
  primaryContactPhone
  primaryContactEmail
  thumbnailUrl
  bannerUrl
  files {
    id
    url
    type
    contentType
    targetId
    targetType
  }
  links {
    id
    url
    type
    company
    targetId
    targetType
  }
  genres {
    id
    type
  }
  role
  isVerified
`

const venueSchema = `
  id
  name
  description
  capacity
  address
  placeId
  state
  city
  primaryContactName
  primaryContactPhone
  primaryContactEmail
  thumbnailUrl
  bannerUrl
  files {
    id
    url
    type
    contentType
    targetId
    targetType
  }
  links {
    id
    url
    type
    company
    targetId
    targetType
  }
  genres {
    id
    type
  }
  role
  isVerified
`

const bidSchema = `
  id
  campaignId
  venueId
  venue {
    ${venueSchema}
  }
  eventDate
  accepted
`

const campaignSchema = `
  id
  name
  description
  city
  address
  startDate
  endDate
  numberOfTicketsSold
  ticketPrice
  baseTicketPrice
  qrCode
  thumbnailUrl
  bannerUrl
  tickets {
    id
    userId
    qrCode
    canceledAt
    status
    userId
    isActive
    isExpired
    isCanceled
    isScanned
    user {
      id
      firstName
      lastName
      email
    }
  }
  bandId
  band {
    ${artistSchema}
  }
  bids {
    ${bidSchema}
  }
  acceptedBidId
  acceptedBid {
    ${bidSchema}
  }
  venue {
    ${venueSchema}
  }
  deepLinkUrl
  canceledAt
  deletedAt
`

const ticketSchema = `
  id
  campaignId
  qrCode
  qrCodeScannedCount
  createdAt
  canceledAt
  isScanned
  isCanceled
  isExpired
  isActive
  userId
  user { id, firstName, lastName, email }
  campaign { ${campaignSchema} }
`

const profileSchema = `
  id
  name
  type
  role
  isVerified
`
