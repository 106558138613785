import Utils from '#/libs/Utils'
import Vuex from 'vuex'
import Vue from 'vue'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({

  plugins: [createPersistedState()],

  state: {
    appVersion: null,
    inviteAccess: false,
    campaigns: [],
    tickets: [],
    user: null,
  },

  mutations: {
    clear(state) {
      state.user = null
      state.campaigns = []
      state.tickets = []
    },
    set(state, payload = {}) {
      for (const [key, value] of Object.entries(payload)) {
        // console.log('SET', key, value)
        Vue.set(state, key, value)
      }
    },
    extend(state, payload) {
      for (const [key, value] of Object.entries(payload)) {
        // console.log('EXTEND', key, value)
        Vue.set(state, key, {...state[key], ...value});
      }
    },
    merge(state, payload) {
      for (const [key, value] of Object.entries(payload)) {
        // console.log('MERGE', key, value)
        Vue.set(state, key, Utils.merge(state[key], value))
      }
    }
  },

  actions: {
    clear: ({ commit }) => commit('clear'),
    setInviteAccess: ({ commit }, inviteAccess) => commit('set', { inviteAccess }),
    setCampaigns: ({ commit }, campaigns) => commit('set', { campaigns }),
    setTickets: ({ commit }, tickets) => commit('set', { tickets }),
    setUser: ({ commit }, user) => commit('set', { user }),
    setAppVersion: ({ commit }, appVersion) => commit('set', { appVersion }),
    mergeCampaigns: ({ commit }, campaigns) => commit('merge', { campaigns }),
    mergeTickets: ({ commit }, tickets) => commit('merge', { tickets }),
  },

  getters: {
    appVersion: (state) => state.appVersion,
    inviteAccess: (state) => state.inviteAccess,
    campaigns: (state) => state.campaigns,
    tickets: (state) => state.tickets,
    user: (state) => state.user,
  }
})

