class NavStack {
  constructor (router) {
    this.router = router
    this.stack = []
  }

  replace (route) {
    this.stack.push(route)
    this.router.replace(route).catch(() => {})
  }

  push (newRoute) {
    this.stack.push(newRoute)
    this.router.push(newRoute).catch(() => {})
  }

  back () {
    if (!this.stack[this.stack.length - 1]) {
      this.push({ path: '/' })
      return
    }
    this.stack.push(this.stack[this.stack.length - 1])
    this.router.back()
  }

  pop () {
    this.stack.pop()
    if (!this.stack[this.stack.length - 1]) {
      this.push({ path: '/' })
      return
    }
    this.router.replace(this.stack[this.stack.length - 1]).catch(() => {})
  }
}

export { NavStack }
