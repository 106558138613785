<template>

  <div class="gs-toast" v-if="message">
<!--    <div class="gs-toast&#45;&#45;icon">
      <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
        <path d="M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z" />
      </svg>
    </div>-->
    <div class="gs-toast--copy">
      {{ message }}
    </div>
  </div>

</template>

<script>

export default {

  name: 'Toast',

  data() {
    return {
      message: null,
      promiseToHideMessage: null
    }
  },

  methods: {

    error(message) {
      this.message = message
      clearTimeout(this.promiseToHideMessage)
      this.promiseToHideMessage = setTimeout(() => this.message = null, 3 * 1000)
    },

    success(message) {
      this.message = message
      clearTimeout(this.promiseToHideMessage)
      this.promiseToHideMessage = setTimeout(() => this.message = null, 3 * 1000)
    }
  }
}

</script>

<style scoped>

.gs-toast {
  background: #713ec0;
  color: #fff;
  position: absolute;
  top: 36px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  padding: 12px 24px;
  border-radius: 4px;
  z-index: 2;
  font-size: 14px;
}

.gs-toast--icon {
  background: #fff;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.gs-toast--icon img {
  width: 12px;
  height: 12px;
}


.fade-in {
  opacity: 0;
  animation: fadeIn 300ms;
  animation-delay: unset;
  animation-fill-mode: forwards;
}

</style>
