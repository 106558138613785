<template>

  <div id="app">

    <navbar />
    <router-view />

    <Toast ref="toast" />

  </div>

</template>

<script>
import { Utils } from '#/libs/Utils'
import { mapActions } from 'vuex'
import Toast from '#/components/Toast'
import { checkPlugins, getAppVersion } from '#/libs/Cordova'
import { log } from '#/libs/logging'
import navbar from '@/components/nav.vue'


export default {
  name: 'App',

  components: {
    Toast,
    navbar
  },

  mounted() {

    //this.$rollbar.info('this is the standard app')
    document.addEventListener('backbutton', this.backButtonCallback, false)
  },

  methods: {

    backButtonCallback() {
      this.$navStack.pop()
    }

  }
}
</script>

<style>

</style>
