<template>
      <div v-if='event'>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css" integrity="sha256-h20CPZ0QyXlBuAw7A+KluUYx/3pK+c7lYEpqLTlxjYQ=" crossorigin="anonymous" />

            <div class="section section-hero">
                <div class="container">
                    <div class="hero_image">
                        <img v-if='!event.bannerUrl' :src="event.band.bannerUrl" alt="" />
                        <img v-if='event.bannerUrl' :src="event.bannerUrl" alt="" />
                    </div>
                </div>
            </div>

            <div class="section section-event">
                <div class="container">
                    
                    <div class="headline headline-center">
                        <b>{{event.name}}</b>
                        <span v-if='event.acceptedBidId'> at <br> <b>{{event.venue.name}}</b><span class="copy" ><br>{{event.venue.address}}<br>{{event.acceptedBidDateTimeMoment.format('dddd, MMMM Do YYYY, h:mm a')}}</span></span>
                        <span class="copy" v-if='!event.acceptedBidId'><br>{{event.address}}<br>{{event.startDateMoment.format('dddd, MMMM Do YYYY')}} to {{event.endDateMoment.format('dddd, MMMM Do YYYY')}}</span>
                        <br>
                        <span class="copy">Ticket Price: ${{event.ticketPriceFixed}}</span><br>
<hr>
<div class="headline headline-center"><span class="copy" >Share {{event.name}} with your friends!</span></div>
<ShareNetwork class='fab fah fa-lg fa-facebook-f'
    network="facebook"
    :url="event.deepLinkUrl"
    :title="event.sharingTitle"
    :description="event.sharingDesc"
  >
</ShareNetwork>&nbsp;&nbsp;&nbsp;
<ShareNetwork class='fab fah fa-lg fa-twitter'
    network="twitter"
    :url="event.deepLinkUrl"
    :title="event.sharingTitle"
    :description="event.sharingDesc"
  >
</ShareNetwork>&nbsp;&nbsp;&nbsp;
<ShareNetwork class='fab fah fa-lg fa-linkedin'
    network="linkedin"
    :url="event.deepLinkUrl"
    :title="event.sharingTitle"
    :description="event.sharingDesc"
  >
</ShareNetwork>&nbsp;&nbsp;&nbsp;
<ShareNetwork class='fab fah fa-lg fa-whatsapp'
    network="whatsapp"
    :url="event.deepLinkUrl"
    :title="event.sharingTitle"
    :description="event.sharingDesc"
  >
</ShareNetwork>&nbsp;&nbsp;&nbsp;

<ShareNetwork class='far fah fa-lg fa-comment-dots'
    network="sms"
    :url="event.deepLinkUrl"
    :title="event.sharingTitle"
    :description="event.sharingDesc"
  >
</ShareNetwork>&nbsp;&nbsp;&nbsp;


<ShareNetwork class='far fah fa-lg fa-envelope'
    network="email"
    :url="event.deepLinkUrl"
    :title="event.sharingTitle"
    :description="event.sharingDesc"
  >
</ShareNetwork>

<hr>
                    </div>
                    
                    <div class="two-col">
                        <div class="col">
                            <div class="headline">{{event.band.name}}</div>
                            
                            <div class="copy" v-html="event.escapedDesc"></div>
                            <ol class="copy" type="1">

                                <li>Download Gigstan</li>
                                <li>Get alerted when a show campaign is added</li>
                                <li>Buy tickets to secure your spot at the show</li>
                                <li>Standby by for the exact date and venue confirmation</li>
                                <li>Your tickets are automatically updated and easily accessible from the Gigstan app!</li>

                            </ol>
                        </div>
                        <div class="col">
                            <div class="promo">
                                <div class="subhead subhead-caps">Download Now</div>

                                <div class="btns">
                                    <a class="btn btn-app" target='_blank' href="https://apps.apple.com/us/app/gigstan/id1638380396"><img src="/img/btn-apple.svg" alt="" /></a>
                                    <a class="btn btn-app" target='_blank' href="https://play.google.com/store/apps/details?id=com.gigstan.fan"><img src="/img/btn-google.svg" alt="" /></a>
                                </div>
                                
                                <div class="powered">
                                    <div class="powered-copy">Powered by</div>
                                    <img class="powered-logo" src="/img/logo-gigstan.svg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <a class="terms" href="https://gigstan.com/terms/fan">Terms & Conditions</a>
                </div>
            </div>

      </div>

</template>

<script>

import moment from 'moment'
import axios from 'axios'
import { router } from '../router'


export default {

  name: 'Event',

  components: {  },

  props: {
  },

  data() {
    return {
        event: null,
      eventId: parseInt(this.$route?.params?.eventId)
    }
  },

  computed: {

  },

  methods: {
    moment,

    async fetchEvent() {
        console.log('fetchEvent');
        console.log(window.location.pathname);
        let eventPath = window.location.pathname;
        let eventId = eventPath.substring(8, eventPath.length);
        console.log(eventId);

        let eventInfo = await axios.get('https://api.gigstan.com/public/events/'+eventId)

        console.log('eventInfo', eventInfo);

        if (eventInfo.data != "OK") {
            this.event = eventInfo.data

            this.event.startDateMoment = moment( this.event.startDate);
            this.event.endDateMoment = moment( this.event.endDate);
            this.event.escapedDesc = this.event.description.replace( /(<([^>]+)>)/ig, '').replace(/\n/g, "<br />")
            
            if (this.event.acceptedBidId != null) {
                this.event.acceptedBidDateTimeMoment = moment( this.event.acceptedBid.eventDate);
                this.event.sharingDesc = this.event.name+" featuring "+this.event.band.name+ " is playing " + this.event.acceptedBidDateTimeMoment.format('dddd, MMMM Do YYYY, h:mm a') + "\n"+this.event.description;

            } else {
                this.event.sharingDesc = this.event.name+" featuring " + this.event.band.name+ " is playing " + this.event.startDateMoment.format('dddd, MMMM Do YYYY') +" to " + this.event.endDateMoment.format('dddd, MMMM Do YYYY') + "\n"+this.event.description;
            }

            this.event.sharingTitle = "Join me at "+this.event.name+" featuring "+this.event.band.name+", a Gigstan show!";

            this.event.ticketPriceFixed = (Math.round(this.event.ticketPrice * 100) / 100).toFixed(2);

        } else {
            router.push({ path: '/events/' })
        }

    }
  },
  async beforeMount() {
    await this.fetchEvent()
  },

  created() {
    this.eventId = this.$route?.params?.eventId;
  }

}

</script>

<style>


    .hero_image img {
      display: block;
      margin-left: auto;
      margin-right: auto;
        max-height: 400px;
    }


  a[class^="share-network-"] {
    flex: none;
    color: #FFFFFF;
    background-color: #333;
    border-radius: 3px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 10px 10px 0;
  }
  a[class^="share-network-"] .fah {
    background-color: rgba(0, 0, 0, 0.2);
    padding: 10px;
    flex: 0 1 auto;
  }
  a[class^="share-network-"] span {
    padding: 0 10px;
    flex: 1 1 0%;
    font-weight: 500;
  }
</style>
