<template>
      <div>

            <div class="section section-video">
                <div class="container">
                    <img class="watermark" src="img/icon-gigstan.svg" alt="" />
                    <div class="video">
                        <video autoplay muted loop controls><source src="https://gigstan.com/videos/gigstan-720p.mp4" type="video/mp4"></video>
                    </div>
                </div>
            </div>

            <div class="section section-content">
                <div class="container">

                    <div class="content content-center content-narrow">
                        <div class="headline">
                            The World’s First Marketplace for Fans, Artists, and Venues.
                        </div>
                        <div class="subhead subhead-caps">More live shows</div>
                        <div class="copy">Your favorite bands playing in your area more often.</div>

                        <div class="promo">
                            <div class="subhead subhead-caps">Download Gigstan today!</div>

                            <div class="btns">
                                <a class="btn btn-app" href="https://apps.apple.com/us/app/gigstan/id1638380396"><img src="img/btn-apple.svg" alt="" /></a>
                                <a class="btn btn-app" href="https://play.google.com/store/apps/details?id=com.gigstan.fan"><img src="img/btn-google.svg" alt="" /></a>
                            </div>
                        </div>

                    </div>

                    <div class="content">
                        <div class="two-col">
                            <div class="col">
                                <div class="headline">
                                    More Rewards. Less Risk.
                                </div>
                                <div class="subhead">Crowd Funding with Refunding</div>
                                <div class="copy">Reserve a ticket to a show via a campaign. Get your ticket details when the campaign succeeds.</div>
                                <div class="copy">Full refund if campaign isn't successful</div>
                            </div>
                            <div class="col">
                                <div class="headline">
                                    Critical Mass
                                </div>
                                <div class="subhead">Power to the Fans. Power in Numbers.</div>
                                <div class="copy">Your voice drives your favorite artist to you and your towns and helps them understand where their fans are. Make your voice heard.</div>
                            </div>
                        </div>
                    </div>

                    <div class="content content-center content-narrow">
                        <div class="headline">
                            Contact Us
                        </div>
                        <div class="copy">Your favorite bands playing in your area more often.</div>
                        <a class="btn" target='_blank' href="mailto:support@gigstan.com?subject=Gigstan%Connect">Contact Us</a>
                    </div>

                    <a class="terms" target='_blank' href="https://gigstan.com/terms/fan">Terms & Conditions</a>
                </div>
            </div>

            <div class="modal">
                <div class="modal-dialog">
                    <div class="modal-body">

                        <div class="headline">
                            Join the list!
                        </div>
                        <div class="subhead">Crowd Funding Live Music</div>
                        <div class="copy">The World’s First Marketplace for<br>Fans, Artists, and Venues.</div>
                        <div class="copy">Sign up today and we’ll let you know when we’re ready!</div>

                        <div class="form">
                            <div class="form-field">
                                <input class="form-input" placeholder=" ">
                                <label class="form-label">Full Name</label>
                            </div>
                            <div class="form-field">
                                <input class="form-input" placeholder=" ">
                                <label class="form-label">Email</label>
                            </div>
                        </div>

                        <div class="btns">
                            <a class="btn close-waitlist" href="#">Cancel</a>
                            <a class="btn btn-primary close-waitlist" href="#">Join the waitlist</a>
                        </div>

                    </div>
                </div>
            </div>
          </div>

</template>

<script>

import moment from 'moment'
import { router } from '../router'

const urlParams = new URLSearchParams(window.location.search);
const _branch_referrer = urlParams.get('_branch_referrer')


export default {

  name: 'Landing',

  components: {  },

  props: {
  },

  data() {
    return {
      eventId: parseInt(this.$route?.params?.eventId),
      branchReferralId: _branch_referrer
    }
  },

  computed: {

  },

  methods: {
    moment
  },

  created() {
    this.eventId = this.$route?.params?.eventId;
  },

  async beforeMount() {
    console.log('_branch_referrer', _branch_referrer);

    //if (_branch_referrer !== undefined && _branch_referrer !== null && _branch_referrer !== '') {
    //    router.push({ path: '/events/traffic-music-presents-jon-muq' })
    //}

  }
}

</script>
