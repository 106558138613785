import App from './views/App'
import store from "@/store"
import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
import Vuelidate from 'vuelidate'
import { Api } from '#/libs/Api'
import { NavStack } from '#/libs/NavStack'
import { router } from './router'
import { setupLogging } from '#/libs/logging'
import VueGtag from 'vue-gtag'
import VueMeta from 'vue-meta'
import VueSocialSharing from 'vue-social-sharing'

Vue.config.devtools = true

setupLogging().catch(console.error)

VueClipboard.config.autoSetContainer = true
Vue.config.productionTip = false

Vue.use(VueClipboard)
Vue.use(Vuelidate)
Vue.use(VueMeta)
Vue.use(VueSocialSharing);


Vue.use(VueGtag, {
  pageTrackerScreenviewEnabled: true,
  config: { id: "G-LP81JPS62R" }
}, router);

Vue.prototype.$api = new Api({store, router});
Vue.prototype.$navStack = new NavStack(router);

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
