import Vue from 'vue'
import Router from 'vue-router'
import event from '@/views/event.vue'
import events from '@/views/events.vue'
import landing from '@/views/landing.vue'
import landing_pro from '@/views/landing_pro.vue'
Vue.use(Router)

console.log("BASE_URL", process.env.BASE_URL)
//console.log('location.hash', location.hash, )

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '*',
      name: 'landing',
      component: landing
    },
    {
      path: '/pro',
      name: 'landing_pro',
      component: landing_pro
    },
    {
      path: '/pro/*',
      name: 'landing_pro',
      component: landing_pro
    },
    {
      path: '/events/',
      name: 'events',
      component: events
    },
    {
      path: '/events/:eventId',
      name: 'event',
      component: event
    }
  ],

  scrollBehavior (to, from, savedPosition) {
    return !savedPosition ? { x: 0, y: 0 } : savedPosition
  }
})


if (location.hash != ''){
  let newURL = location.hash.substring(1, location.hash.length);
  router.push(newURL)
}


router.beforeEach((to, from, next) => {
  console.log(`from: ${from?.fullPath}`)
  console.log(`to: ${to?.fullPath}`)
  next()
})

export { router }
