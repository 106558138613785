<template>

        <div class="section section-content">
            <div class="container">

                <div v-if='eventLoaded && events.length' class="content">
                    <div  class="headline">
                        Live Shows
                    </div>

                    <div v-if='eventLoaded && !events.length'>
                            <div class="subhead">We are working on getting some awesome shows for you. Check back soon for more! <br><br>For Secret Shows, contact artist or venue. 
                        </div>
                    </div>

                    <div v-if='0'>List of Shows</div>
                    
                    <div v-if='eventLoaded && events.length' class="events">
                    
                        <div v-if='eventLoaded && events.length' v-for="event in eventsSorted" class="event"  v-on:click="eventClicked(event)">
                            <div class="event-info">
                                <div class="event-name">{{event.name}}</div>
                                <div class="event-copy">
                                    <div v-if='event.acceptedBidId'> 
                                        Location: {{event.venue.name}}<br>
                                        Address: {{event.venue.address}}<br>
                                        Date &amp; Time: {{event.acceptedBid.eventDateMoment.format('dddd, MMMM Do YYYY, h:mm a')}}</div>
                                    <div v-if='!event.acceptedBidId'> 
                                    Location: {{event.address}} <br> Date &amp; Time Range: {{event.startDateMoment.format('dddd, MMMM Do YYYY')}} to {{event.endDateMoment.format('dddd, MMMM Do YYYY')}}</div>
                                </div>
                            </div>
                            <div class="event-action">
                                <a class="btn btn-primary" href="#">More info</a>
                            </div>
                        </div>
                    </div>
                    
                </div>                  
                
                <a class="terms" target='_blank' href="https://gigstan.com/terms/fan">Terms &amp; Conditions</a>
            </div>
                
        </div>

</template>

<script>

import moment from 'moment'
import { router } from '../router'
import axios from 'axios'
import _ from 'lodash'

export default {

  name: 'Events',

  components: {  },



  props: {
  },

  data() {
    return {
      eventId: parseInt(this.$route?.params?.eventId),
      eventLoaded:false,
      events: []
    }
  },

  computed: {
    eventsSorted: function() {
      this.events.sort(function(a, b) {
        a = a.startDateMoment.unix();
        b = b.startDateMoment.unix();
        var results = a > b ? -1 : a < b ? 1 : 0;
        return results * -1; // desc
      });
      return this.events;
    }    
  },

  methods: {
    moment,
    eventClicked (event) {
        console.log('eventClicked', event);
        router.push({ path: '/events/'+event.vanityUrl })
    },
    async fetchEvents() {
        console.log('fetchEvents');

        let eventInfo = await axios.get('https://api.gigstan.com/public/events')

        console.log(eventInfo);
        if (eventInfo != "OK") {
            this.events = eventInfo.data.events
            for (let event of this.events) {
                if (event.acceptedBidId != null) {
                    console.log(event, event.acceptedBidId);
                    event.acceptedBid.eventDateMoment = moment(event.acceptedBid.eventDate);
                }
                event.startDateMoment = moment( event.startDate);
                event.endDateMoment = moment( event.endDate);
               
            }
            console.log(this.events);
        } else {
        }



        this.eventLoaded = true;
    }
  },

  created() {
    this.eventId = this.$route?.params?.eventId;
  },
  async beforeMount() {
    await this.fetchEvents()
  }

}



/*
            <teleport to="head">
                <component :is="'script'" type="application/ld+json">
                  {
                    "@context": "https://schema.org",
                    "@type": "WebSite",
                    "url": "https://www.example.com/",
                    "potentialAction": {
                      "@type": "SearchAction",
                      "target": "https://query.example.com/search?q={search_term_string}",
                      "query-input": "required name=search_term_string"
                    }
                  }
                </component>
            </teleport>
*/            



</script>
