<template>
      <div>
            <div class="section section-video">
                <div class="container">
                    <img class="watermark" src="img/icon-gigstan.svg" alt="" />
                </div>
            </div>

            <div class="section section-content">
                <div class="container">

                    <div class="content content-center content-narrow">
                        <div class="headline">
                            The World’s First Marketplace for Fans, Artists, and Venues.
                        </div>
                        <div class="subhead subhead-caps">More live shows</div>
                        <div class="copy">Your favorite bands playing in your area more often.</div>                        

                        <div class="promo">
                            <div class="subhead subhead-caps">Download Gigstan Pro today!</div>

                            <div class="btns">
                                <a class="btn btn-app" href="https://apps.apple.com/us/app/gigstan-pro/id1592443130"><img src="/img/btn-apple.svg" alt="" /></a>
                                <a class="btn btn-app" href="https://play.google.com/store/apps/details?id=com.gigstan.pro"><img src="/img/btn-google.svg" alt="" /></a>
                            </div>
                        </div>

                    </div>

                    <div class="content">
                        <div class="two-col">
                            <div class="col">
                                <div class="headline">
                                    More Rewards. Less Risk.
                                </div>
                                <div class="subhead">Crowd Funding with Refunding</div>
                                <div class="copy">Reserve a ticket to a show via a campaign. Get your ticket details when the campaign succeeds.</div>
                                <div class="copy">Full refund if campaign isn't successful</div>
                            </div>
                            <div class="col">
                                <div class="headline">
                                    Critical Mass
                                </div>
                                <div class="subhead">Power to the Fans. Power in Numbers.</div>
                                <div class="copy">Your voice drives your favorite artist to you and your towns and helps them understand where their fans are. Make your voice heard.</div>
                            </div>
                        </div>
                    </div>

                    <div class="content content-center content-narrow">
                        <div class="headline">
                            Contact Us
                        </div>
                        <div class="copy">Your favorite bands playing in your area more often.</div>
                        <a class="btn" target='_blank' href="mailto:support@gigstan.com?subject=Gigstan%Connect">Contact Us</a>
                    </div>                    
                    
                    <a class="terms" target='_blank' href="https://gigstan.com/terms/fan">Terms & Conditions</a>
                </div>
            </div>

            <div class="">
                <RegisterVenue v-if='isVenue && !registerDisabled' />
                <RegisterArtist v-if='isArtist && !registerDisabled' />
            </div>
      </div>

</template>

<script>


import RegisterVenue from '@/components/register/register_venue.vue'
import RegisterArtist from '@/components/register/register_artist.vue'
import moment from 'moment'

export default {

    name: 'LandingPro',

    beforeUpdate () {
        console.log('beforeUpdate');
    },

    updated () {
        console.log('updated');
    },
    watch: {
        $route(to, from) {
          // react to route changes...
          let hostname = window.location.href;
          console.log(hostname)
          this.isArtist = hostname.toLowerCase().includes('artist')
          this.isVenue = hostname.toLowerCase().includes('venue') || hostname.toLowerCase().includes('space')
        }
    },
    mounted () {
      let hostname = window.location.href;
      console.log(hostname)
      this.isArtist = hostname.toLowerCase().includes('artist')
      this.isVenue = hostname.toLowerCase().includes('venue') || hostname.toLowerCase().includes('space')

    },
    components: {
        RegisterVenue,
        RegisterArtist,
    },

  props: {
  },

  data() {
    return {
      isArtist: null,
      isVenue: null,
      registerDisabled: true
    }
  },

  computed: {

  },

  methods: {
    moment
  },

  created() {
    this.eventId = this.$route?.params?.eventId
  }

}

</script>
