import Rollbar from 'rollbar'
import Utils from '#/libs/Utils'
import Vue from 'vue'

let rollbar

export async function setupLogging(appVersion = '0.0.0') {
  try {
    rollbar = new Rollbar({
      accessToken: process.env.VUE_APP_LOGGING_TOKEN,
      captureUncaught: true,
      captureUnhandledRejections: true,
      autoInstrument: false,
      payload: {
        environment: process.env.VUE_APP_LOGGING_ENVIRONMENT,
        client: {
          javascript: {
            code_version: appVersion
          }
        }
      }
    })

    // prevent rollbar from grouping messages together by giving them all unique fingerprints
    rollbar.configure({
      transform: payload => {
        payload.fingerprint = Utils.id()
      }
    })

    Vue.prototype.$rollbar = rollbar

  } catch (err) {

    console.error('Failed to setupLogging', err)
  }
}

export function log(message, data) {
  try {

    console.log(message, data)

    rollbar.info(message, data)

  } catch (err) {

    console.error('Failed to log', console.error)
  }
}

export function logWarning(message, error) {
  try {

    console.warn(message, error)

    rollbar.warning(message, error)

  } catch (err) {

    console.error('Failed to logWarning', err)
  }
}

export function logException(message, error) {
  try {

    console.error(message, error)

    rollbar.error(message, error)

  } catch (err) {

    console.error('Failed to logException', err)
  }
}

export function trackPersonInLogging(user) {
  try {
    rollbar.configure({
      payload: {
        person: {
          id: user?.id,
          email: user?.email
        }
      }
    })
  } catch (err) {

    console.error('Failed to trackPerson', err)
  }
}
