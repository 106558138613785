<template>
    <div  :class="{ 'gs-register--form-close': !isOpen, 'gs-register--form-open': isOpen }">
        <div v-if='isOpen' @click="isOpen=!isOpen" class="gs-register--btn-x">X&nbsp;&nbsp;&nbsp;</div>
        <div v-if='!isOpen' @click="isOpen=!isOpen" class="gs-register--btn-close gs-register--btn">Apply to GigStan Pro</div>
        <div v-if='isOpen' class='gs-register--headline'>
          <div class='gs-register--headline1'><img width='200' src="/img/gigstan-fan-logo.png"/></div>
          <div class='gs-register--headline1'>Venue Application</div>
          <div  class='gs-register--headline2'><br>Crowdfunding Live Music. <br><br>
          The world’s first <br/>
          marketplace for<br/>
          Venues, Artists, and Fans</div>
          <br/>
<div v-if='!isRegistered'>          
    <form  target="_self" method="POST" id="mG61Hd">
    <div  class='gs-register--headline3'>
            <div class="input">
              <input  ref="name" placeholder="Venue Name" name='entry.135513117' type="text" class="input-field" v-model="contact.name" required/>
            </div>
    </div>
    <div  class='gs-register--headline3'>
            <div class="input">
              <input  ref="email" name='entry.2137391189' type="text" autocomplete="false"  placeholder="Contact Name" class="input-field" v-model="contact.contact_name" />
            </div>
    </div>
    <div  class='gs-register--headline3'>
            <div class="input">
              <input  ref="email" name='entry.2137391189' type="text" autocomplete="false"  placeholder="Contact Email" class="input-field" v-model="contact.email" required/>
            </div>
    </div>

    <div  class='gs-register--headline3'>
            <div class="input">
              <input  ref="relationship" name='entry.46424058' type="text" autocomplete="false"  placeholder="Relationship to Venue" class="input-field" v-model="contact.relationship" />
            </div>
    </div>


    <div  class='gs-register--headline3'>
            <div class="input">
              <input  ref="website" name='entry.1330774319' type="text" autocomplete="false"  placeholder="Website" class="input-field" v-model="contact.website" />
            </div>
    </div>

    <div  class='gs-register--headline3'>
            <div class="input">
              <input  ref="social" name='entry.1056193784' type="text" autocomplete="false"  placeholder="Social Link" class="input-field" v-model="contact.website2" />
            </div>
    </div>
    <div  class='gs-register--headline3'>
      <vue-google-autocomplete ref="address" id="map" classname="input-field" autocomplete="true" placeholder="Address" v-on:placechanged="getAddressData">
      </vue-google-autocomplete>
    </div>

    <div  class='gs-register--headline3'>
            <div class="input">
              <input  ref="notes" placeholder="Notes"  name='entry.2137391189' type="text" class="input-field" v-model="contact.notes" />
            </div>
    </div>

    <button :disabled="isInFlight ? '' : disabled" class='gs-register--submit' type='submit' @click="submitForm">Apply to GigStan Pro</button>
    </form>
</div>

<div v-if='isRegistered'>

      <div  class='gs-register--headline2'>
          Awesome!
      </div>
      <div  class='gs-register--headline2'><br>GigStan will contact you<br> to complete registration.</div>

</div>


<br>
<div  class='gs-register--headline3'>Please note, GigStan is currently invitation only.</div>


        </div>
    </div>
</template>

<script>

import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import axios from 'axios';
import VueGoogleAutocomplete from "vue-google-autocomplete";


export default {
    name: 'RegisterVenue',
    setup () {
      return { v$: useVuelidate() }
    },
    components: {
      VueGoogleAutocomplete
    },
    data() {
      return {
        isOpen: 1,
        isRegistered: 0,
        isInFlight: 0,
        disabled: false,
        contact: {
          name: '',
          contact_name: '',
          website: '',
          website2: '',
          relationship: '',
          address: '',
          notes: '',
          email: ''
        }
      }
    },
    methods: {
      getAddressData (addressData, placeResultData, id) {
        console.log('addressData', addressData, placeResultData, id)
        this.contact.address = JSON.stringify(addressData);
      },
      submitForm(e) {
        e.preventDefault();

        this.$refs['name'].classList.remove('error')
        this.$refs['email'].classList.remove('error')
        //this.$refs['address'].classList.remove('error')
        this.$refs['notes'].classList.remove('error')

        this.v$.$validate() // checks all inputs
        if (!this.v$.$error) {
          this.isInFlight = true;
          let action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSd0hYMVOYopLl9ZXTUS1G33sMWRUR6L4-blbSc3A7ompDwUtg/formResponse"
          let formData = new FormData();
/*
<input type="hidden" name="entry.322255961" value="">
<input type="hidden" name="entry.1574269010" value="">
<input type="hidden" name="entry.689579889" value="">
<input type="hidden" name="entry.1132239318" value="">
*/

          formData.append('entry.322255961', this.contact.name);
          formData.append('entry.655801295', this.contact.website);
          formData.append('entry.237365702', this.contact.website2);
          formData.append('entry.253081694', this.contact.contact_name);
          formData.append('entry.1663116271', this.contact.relationship);
          formData.append('entry.1574269010', this.contact.email);
          formData.append('entry.689579889', this.contact.address);
          formData.append('entry.1132239318', this.contact.notes);
           axios.post(action, formData)
                 .then(() => {
                 })
                 .catch(() => {
                 }).finally(() => {
                     this.isRegistered = true; 
                     this.isInFlight = false;
                     setTimeout( ()=> {
                      this.isOpen = false;
                      this.isRegistered = false; 
                       this.contact.name = "";
                       this.contact.email = "";
                     }, 5000)
                 });

        } else {
          this.v$.$errors.forEach((err) => {
            console.log(err);
            console.log(this.$refs[err['$property']].classList.add('error'));
            //this.$refs[err['$property']].$el.
          });
//          alert('Form failed validation')
        }
      }
    },
    validations () {
      return {
        contact: {
          name: { required },
          address: { required },
          email: { required, email } // Matches this.contact.email
        }
      }
    }
}
</script>

<style scoped>

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #713EC0;
  opacity: 1; /* Firefox */
}

.gs-register--form-close {
  z-index: 10;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 42px;

  position: absolute;
  width: 300px;
  height: 42px;
  right: 30px;
  top: 100px;
  text-align: center;
  color: #713EC0;
  background: #FFFFFF;
  border-radius: 20px;

}

.gs-register--form-open {
  z-index: 10;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 42px;

  position: absolute;
  width: 300px;
  height: 780px;
  right: 30px;
  top: 100px;
  text-align: center;
  color: #713EC0;
  background: #FFFFFF;
  border-radius: 20px;

  overflow-y: auto;

}

.gs-register--headline1 {

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 42px;

}

.gs-register--headline2 {

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

}

.gs-register--headline3 {

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-size: 10px;
  line-height: 20px;

}


.gs-register--submit {

  font-family: 'Montserrat';
  justify-content: center;
  height: 30px;
  border-color: #FFFFFF;
  border-style: solid;
  
  cursor: pointer;
  width: 200px;
  text-align: center;
  color: #FFFFFF;
  background: #713EC0;
  border-radius: 20px;

}


.gs-register--btn {

  cursor: pointer;

}

.gs-register--btn-x {

  font-family: Helvetica, Verdana;
  cursor: pointer;
  text-align: right;
  top: 50px;
  right: 10px;

  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 42px;
}



.input {
  display: flex;
  width: 280px;
  padding-left: 10px;
}

.input-label {
  position: absolute;
  transition: .25s ease;
}

.input-field {
  border: 0;
  z-index: 1;
  width: 280px;
  background-color: transparent;
  border-bottom: 2px solid #eee; 
  margin-bottom: 20px;
  font: inherit;
  &:focus, &:valid {
    outline: 0;
    border-bottom-color: #6658d3;
    &+.input-label {
      color: #6658d3;
      transform: translateY(-1.5rem);
    }
  }
}

.error {
  border-bottom: 2px solid red; 
}

.action {
  margin-top: 2rem;
}

</style>