import moment from 'moment'

class Utils {

  static async wait(ms) {

    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  static ensureArray(array) {

    if (!array) return []

    if (!Array.isArray(array)) array = [array]

    return array
  }

  static eventFunded(event) {
    return event.signups / event.signupThreshold >= 1.0
  }

  static extension(filename) {

    if (!filename) return ''

    const parts = filename.split('.')

    if (!Utils.hasAny(parts)) return ''
    if (filename === parts[0]) return ''

    const ext = '.' + parts[parts.length - 1]

    return ext
  }

  static extractErrorCode(str) {
    const code = /Error: (\d+)/g
    const codeResult = code.exec(str)
    if (codeResult) {
      return codeResult[1]
    } else {
      return null
    }
  }

  static filename(path) {

    if (!path) return null

    return path.replace(/^.*[\\\/]/, '')
  }

  static formatDate(date) {
    return moment(date).format('MMM D')
  }

  static getEventPercent(event) {
    return Math.round((event.signups / event.venue.capacity) * 100, 0)
  }

  static hasAny(items = []) {

    return Utils.hasLength(items)
  }

  static hasLength(items = [], min = 1) {

    if (!items) return false

    return (items.length >= min)
  }

  static id() {
    let dt = new Date().getTime()
    let uuid = 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      let r = (dt + Math.random() * 16) % 16 | 0
      dt = Math.floor(dt / 16)
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16)
    })
    return uuid
  }

  static isArray(value) {

    return Array.isArray(value)
  }

  static isObject(input) {

    return !!(
      input &&
      typeof input === 'object' &&
      !Array.isArray(input)
    )
  }

  static isString(input) {

    return !!(input && typeof input === 'string')
  }

  static merge(list = [], updates = [], prop = 'id') {

    list = Utils.ensureArray(list)

    if (!Utils.hasLength(updates)) return list

    updates.forEach(update => {

      let source = list.find(x => update[prop] === x[prop])

      if (source) Object.assign(source, update)

      else list.push(update)
    })

    return list
  }

  static parse(input) {
    try {
      return JSON.parse(input)
    } catch (err) {
      return input
    }
  }

  static parseName(name) {

    if (!name) return

    let parts = name.trim().split(' ').filter(Boolean)

    if (!parts.length) return

    if (parts.length === 1) return { firstName: parts[0], lastName: undefined }

    return {
      firstName: parts.slice(0, parts.length - 1).join(' '),
      lastName: parts[parts.length - 1]
    }
  }

  static parseQuery(url) {
    let arr
    const res = {}
    url = url.split('#')[0]
    arr = url.split('?')
    arr.shift()
    const queryStr = arr.join('?')
    if (queryStr.trim().length === 0) {
      return res
    }
    arr = queryStr.split('&')
    for (let i = 0; i < arr.length; i++) {
      const itemArr = arr[i].split('=')
      const name = itemArr.shift()
      const value = itemArr.join('=')
      res[name] = value
    }
    return res
  }

  static pick(o, ...props) {
    return Object.assign({}, ...props.map(prop => ({ [prop]: o[prop] })))
  }

  static random(max = 100) {

    return Math.floor(Math.random() * Math.floor(max));
  }

  static stringify(obj, size) {

    if (!obj) return obj

    if (!Utils.isObject(obj) && !Utils.isArray(obj)) return obj

    return (size) ? JSON.stringify(obj, null, size) : JSON.stringify(obj)
  }

  static toBoolean(value) {
    if (!value)
      return false
    if (Utils.isString(value)) {
      return (value.toString().toLowerCase() === 'true')
    } else
      return !!(value)
  }

  static toHtmlInputDateFormat(date) {

    return moment(date).format('YYYY-MM-DD')
  }

  static today() {
    return moment().startOf('day')
  }

  static tomorrow() {
    return moment().startOf('day').add(1, 'day')
  }

  static nextWeek() {
    return moment().startOf('day').add(1, 'week')
  }

  static lastWeek() {
    return moment().startOf('day').add(-1, 'week')
  }

  static yesterday() {
    return moment().startOf('day').add(-1, 'day')
  }

  static startOfDay(date) {
    return moment(date).startOf('day')
  }
}

export function hasProtocol(url){
  try {
    if(!url) return false
    let parsed = new URL(url)
    return !!(parsed?.protocol);
  } catch(err) {
    return false
  }
}

export function parseJSON(input) {
  try {
    return JSON.parse(input)
  } catch (err) {
    return input
  }
}

export function test(str, re, word, flags) {

  if (!str && !re) return true;   // if both are falsy, consider them matching

  if (!str || !re) return false;  // if only one is missing, there can't possibly be a match

  if (word) re = "^" + re + "$";

  if (flags === undefined) flags = 'i';

  const regex = new RegExp(re, flags);

  return regex.test(str);
}

export function toBoolean(value) {
  if (!value)
    return false
  if (Utils.isString(value)) {
    return (value.toString().toLowerCase() === 'true')
  } else
    return !!(value)
}

export function groupBy(collection, prop = 'id') {
  const grouped = {};

  collection.forEach(item => {
    if (!item.userId) {
      throw new Error('Object in collection is missing the userId field.');
    }

    if (!grouped[item[prop]]) {
      grouped[item[prop]] = [];
    }

    grouped[item[prop]].push(item);
  });

  return grouped;
}

export function isPhoneNumber(phoneNumber) {
  // Remove any non-digit characters from the phone number
  const cleaned = phoneNumber.replace(/\D/g, "");
  // Check if the cleaned phone number has 10 digits
  const regex = /^\d{10,12}$/;
  return regex.test(cleaned);
}

export function isEmail(emailAddress) {
  // Regular expression pattern for validating email addresses
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  // Use the test() method to check if the email address matches the pattern
  return regex.test(emailAddress);
}

export function toPhrase(str) {
  // split string at each capital letter
  const words = str.split(/(?=[A-Z])/);
  // convert each word to lowercase and join into a sentence
  const sentence = words.map(word => word.toLowerCase()).join(' ');
  // capitalize the first letter of the sentence and return
  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
}

export function shiftArrayById(arr, id) {

  const index = arr.findIndex((obj) => obj.id === id);

  if (index === -1) return arr

  return arr.slice(index).concat(arr.slice(0, index));
}

export function toPrice(value) {
  return Number(value).toFixed(2);
}

export default Utils

export { Utils }
